import { HashRouter as Router, Routes, Route } from "react-router-dom";
import { lazy, Suspense } from "react";
import "./App.css";

const Login = lazy(() => import("./screens/auth/Login"));
const Dashboard = lazy(() => import("./screens/dashboard"));
const Orders = lazy(() => import("./screens/orders"));

function App() {
  return (
    <Router>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/orders" element={<Orders />} />
        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;
